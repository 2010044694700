import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';
const initialState = {
    invoices: {},
    loading: false,
};

export const createInvoice = createAsyncThunk(
    'invoice/createInvoice',
    async (data, { rejectWithValue }) => {
        try {
            const invoiceData = await authAxiosInstance.post(`invoice`, data);
            console.log(invoiceData.data.data, 'check again');
            toast.success('Invoice Created');
            return invoiceData.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to create invoice');
        }
    }
);

export const fetchInvoices = createAsyncThunk(
    'invoice/fetchInvoice',
    async (data, { rejectWithValue }) => {
        try {
            const string = QueryString.stringify(data);
            const invoiceData = await authAxiosInstance.get(
                `invoice?${string}`
            );
            return invoiceData.data.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.message) toast.error(error.response.message);
            else toast.error('failed to fetch invoice');
        }
    }
);

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: initialState,
    reducers: {
        reset: (state, action) => {
            state['invoices'] = [];
        },
    },
    extraReducers: {
        [createInvoice.pending]: (state) => {
            state.loading = true;
        },
        [createInvoice.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [createInvoice.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchInvoices.pending]: (state) => {
            state.loading = true;
        },
        [fetchInvoices.fulfilled]: (state, action) => {
            state.loading = false;
            state.invoices = action.payload;
        },
        [fetchInvoices.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getInvoice = (state) => state.invoice;
export const { reset } = invoiceSlice.actions;
export default invoiceSlice.reducer;
