import { FieldArray, FormikProvider, useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
// import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import Modal from 'react-modal';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import DatePicker from 'react-datepicker';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useEffect, useMemo, useState } from 'react';
import {
    fetchReminderSubjects,
    getReminderSubject,
} from '../../app/reducers/ReminderSubject/reminderSubjectSlice';
import { generateOptions } from '../../utils/Utils';
import {
    editReminderPopUp,
    fetchReminderPopUps,
    openReminderPopUp,
} from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { ClipLoader } from 'react-spinners';
import { X } from 'react-feather';
import FormikTime from '../../components/formik/FormikTime';
import moment from 'moment';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
const ViewReminderPopUp = ({ isOpen, setIsOpen, data }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const { reminderSubject } = useSelector(getReminderSubject);
    useEffect(() => {
        dispatch(fetchReminderSubjects());
    }, [data]);

    const subjectData = useMemo(
        () => reminderSubject.docs ?? [],
        [reminderSubject.docs]
    );
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '700px',
            width: '-webkit-fill-available',
            maxHeight: '80%',
            overflow: 'auto',
        },
    };
    console.log('View POpUp');
    const formik = useFormik({
        initialValues: {
            notes: data?.notes,
            subject: data?.subject,
            //Date Time Fields Start
            selectTime: 'default',
            date: new Date(),
            time: new Date(),
            defaultTime: '',
            //Date Time Fields Ends
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let dateTimeNew;
            if (values.selectTime == 'default') {
                let dateTime = moment(values.date).format('DD/MM/YYYY');
                dateTimeNew = `${dateTime} ${values.defaultTime}`;
            } else {
                let dateTime = moment(values.date).format('DD/MM/YYYY');
                dateTimeNew = `${dateTime} ${values.time.toTimeString()}`;
            }
            let finalDate = moment(dateTimeNew, 'DD/MM/YYYY HH:mm:ss Z').unix();

            //Delete Data from Object
            delete values.selectTime;
            delete values.time;
            delete values.defaultTime;
            //Delete Data end
            await dispatch(
                editReminderPopUp({
                    _id: data?._id,
                    date: finalDate,
                    subject: values.subject,
                    notes: values.notes,
                    status: 'pending',
                })
            );
            setLoading(false);
            dispatch(
                fetchReminderPopUps({
                    date: { $lt: moment(new Date()).unix() },
                    status: { $ne: 'cancel' },
                    limit: 1,
                })
            );
            setEdit(false);
            toast.dismiss();
            setIsOpen();
        },
    });

    const read = async (e) => {
        e.stopPropagation();
        setLoading(true);
        let detail = {
            _id: data?._id,
            status: 'read',
        };
        await dispatch(editReminderPopUp(detail));
        await dispatch(
            fetchReminderPopUps({
                date: { $lt: moment(new Date()).unix() },
                status: { $ne: 'cancel' },
                limit: 1,
            })
        );
        setLoading(false);
        setIsOpen();
    };

    const Cancel = async (e) => {
        e.stopPropagation();
        setLoading(true);
        let detail = {
            _id: data?._id,
            status: 'cancel',
            path: data?.path,
            path_id: data?.path_id,
            reminderType: data?.reminderType,
        };
        // await dispatch(editReminderPopUp(detail));
        await authAxiosInstance.patch('reminderPopUp/cancel-reminder', detail);
        await dispatch(
            fetchReminderPopUps({
                date: { $lt: moment(new Date()).unix() },
                status: { $ne: 'cancel' },
                limit: 1,
            })
        );
        setLoading(false);
        setIsOpen();
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Example Modal"
            onRequestClose={(e) => {
                setIsOpen();
            }}
        >
            {edit ? (
                <form onSubmit={formik.handleSubmit} className="z-10">
                    <h4 className="font-bold mb-1 text-center">
                        Reschedule Reminder
                    </h4>
                    <FormikSelectGroup
                        label="Reminder Subject"
                        formik={formik}
                        name="subject"
                        required
                        options={generateOptions({
                            array: subjectData.filter(
                                (d) => d.type === data?.type
                            ),
                            labelField: 'name',
                            valueField: 'name',
                        })}
                    />
                    <div>
                        <div className="block text-sm font-medium">Date</div>
                        <DatePicker
                            selected={formik.values.date}
                            onChange={(date) =>
                                formik.setFieldValue('date', date)
                            }
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        <FormikSelectGroup
                            label="Select Time Type"
                            formik={formik}
                            name="selectTime"
                            required
                            options={generateOptions({
                                array: [
                                    { label: 'Default', value: 'default' },
                                    { label: 'Other', value: 'other' },
                                ],
                                labelField: 'label',
                                valueField: 'value',
                            })}
                        />
                        {formik.values.selectTime == 'default' && (
                            <>
                                <div>Time</div>
                                <div className="flex flex-wrap gap-5">
                                    <div>
                                        <input
                                            type="radio"
                                            id="11"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="11:00:00"
                                        />
                                        <label className="ml-2" for="9">
                                            11:00 AM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="1"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="13:00:00"
                                        />
                                        <label className="ml-2" for="11">
                                            01:00 PM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="4"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="16:00:00"
                                        />
                                        <label className="ml-2" for="4">
                                            04:00 PM
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="6"
                                            name="defaultTime"
                                            onChange={formik.handleChange}
                                            value="18:00:00"
                                        />
                                        <label className="ml-2" for="6">
                                            06:00 PM
                                        </label>
                                    </div>
                                    <br></br>
                                </div>
                            </>
                        )}
                        {formik.values.selectTime == 'other' && (
                            <>
                                <FormikTime
                                    name="time"
                                    formik={formik}
                                    label="Time"
                                />
                            </>
                        )}
                        <FormikTextareaGroup
                            name="notes"
                            formik={formik}
                            label="Notes"
                        />
                        <div className="flex gap-5">
                            {loading ? (
                                <ClipLoader />
                            ) : (
                                <PrimaryButton type="submit">
                                    Submit
                                </PrimaryButton>
                            )}
                            <PrimaryButton
                                type="button"
                                onClick={() => setEdit(false)}
                            >
                                View Pop Up
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="m-4 flex flex-col gap-4 z-10">
                    <h4 className="font-bold mb-1 text-center text-xl">
                        {data?.subject}
                        <span className="float-right cursor-pointer">
                            {/* <X
                                onClick={(e) => {
                                    setIsOpen();
                                }}
                            /> */}
                        </span>
                    </h4>
                    <div className="text-center">
                        <span className="font-bold">PO No. :-</span>{' '}
                        {data?.poNumber}
                    </div>
                    <div className="flex flex-wrap justify-between">
                        <div className="flex flex-col">
                            <div>
                                <span className="font-bold">Customer:-</span>{' '}
                                {data?.customerName}
                            </div>
                            <span>M: {data?.customerMobileNo}</span>
                        </div>
                        <div className="flex flex-col">
                            <div>
                                <span className="font-bold">Vendor:-</span>{' '}
                                {data?.vendorName}{' '}
                            </div>
                            <span>M: {data?.vendorMobileNo}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        <div>
                            <span className="font-bold">Quantity:-</span>{' '}
                            {data?.quantity}
                        </div>
                        <div>
                            <span className="font-bold">Quality Name:-</span>{' '}
                            {data?.qualityName}
                        </div>
                        <div>
                            <span className="font-bold">Rate:-</span>{' '}
                            {data?.rate ? data?.rate : 'NA'}
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-8">
                        <div>
                            <span className="font-bold">Order Date:-</span>{' '}
                            {data?.orderDate
                                ? moment(data?.orderDate).format('DD/MM/YYYY')
                                : 'NA'}
                        </div>
                        <div>
                            <span className="font-bold">Delivery Date:-</span>{' '}
                            {data?.deliveryDate
                                ? moment(data?.deliveryDate).format(
                                      'DD/MM/YYYY'
                                  )
                                : 'NA'}
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-8">
                        <div>
                            <span className="font-bold">Reminder Date:-</span>{' '}
                            {data?.createdAt
                                ? moment(data?.createdAt).format(
                                      'DD/MM/YYYY-HH:mm'
                                  )
                                : 'NA'}
                        </div>
                        <div>
                            <span className="font-bold">Due Date:-</span>{' '}
                            {data?.date
                                ? moment(data?.date * 1000).format(
                                      'DD/MM/YYYY-HH:mm'
                                  )
                                : 'NA'}
                        </div>
                    </div>
                    <div>
                        <span className="font-bold">Note:-</span>{' '}
                        {data?.notes ? data.notes : 'N/A'}
                    </div>
                    <div className="flex gap-5">
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <>
                                {/* <PrimaryButton onClick={read}>
                                    Read
                                </PrimaryButton> */}
                                <PrimaryButton onClick={Cancel}>
                                    Cancel
                                </PrimaryButton>
                                <PrimaryButton onClick={() => setEdit(true)}>
                                    Reschedule
                                </PrimaryButton>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ViewReminderPopUp;
