import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchVendors,
    getVendor,
} from '../../app/reducers/Vendors/vendorSlice';
import {
    fetchCustomers,
    getCustomer,
} from '../../app/reducers/Customer/customerSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { useFormik } from 'formik';
import moment from 'moment';
import { axiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { Loader } from 'react-feather';
import { toast } from 'react-toastify';
import _ from 'lodash';
// vendor_name: '',
// customer_name: '',
// quality: [],
// rate: '',
// qty_taka: '',
// date_range: '',
const AdvanceSearchModal = ({ isOpen, setIsOpen, setAdvanceSearchData }) => {
    const dispatch = useDispatch();
    const { vendors } = useSelector(getVendor);
    const { customers } = useSelector(getCustomer);
    const { quality } = useSelector(getQuality);

    useEffect(() => {
        // dispatch(fetchVendors());
        // dispatch(fetchCustomers());
        dispatch(fetchQualities({ limit: 300 }));
        // dispatch(fetchContacts({ contact_type: 'vendor' }));
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            vendor: '',
            customer: '',
            qualities: [],
            rate: '',
            qty_taka: '',
            startDate: '',
            endDate: '',
            status: 'open', // issued = open, billed = billed, cancelled = cancelled
        },
        onSubmit: async (values) => {
            try {
                const string = QueryString.stringify({
                    ...values,
                    startDate:
                        values.startDate &&
                        moment(values.startDate).format('YYYY-MM-DD'),
                    endDate:
                        values.endDate &&
                        moment(values.endDate).format('YYYY-MM-DD'),
                });
                const res = await axiosInstance.get(
                    `/advancePOSearch?${string}`
                );
                console.log(res);
                if (_.isEmpty(res.data?.data)) {
                    setAdvanceSearchData({ reset: true });
                    return toast.error('No purchase orders found!');
                }
                setAdvanceSearchData({
                    data: res.data?.data?.purchaseOrders,
                    reset: false,
                    hasMore: res.data?.data?.hasMore,
                });
                setIsOpen(false);
            } catch (error) {
                console.log(error);
                toast.error('Internal error');
            }
        },
    });

    return (
        <ModalBasic
            modalOpen={isOpen}
            setModalOpen={setIsOpen}
            title={'Advance Search'}
        >
            <div>
                <form onSubmit={formik.handleSubmit} className="m-4">
                    <div className="grid grid-cols-2 gap-4">
                        <FormikAsyncSelect
                            formik={formik}
                            name={'vendor'}
                            label={'Vendor'}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchContacts({
                                        search: value,
                                        contact_type: 'vendor',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: `${ele.contact_name}`,
                                        value: `${ele.contact_id}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(selectedOption) => {
                                const id = selectedOption.value;

                                formik.setFieldValue('vendor', id);
                            }}
                        />
                        {/* <FormikSelectGroup
                            formik={formik}
                            name={'vendor'}
                            label={'Vendor'}
                            options={(vendors?.docs ?? []).map((vendor) => ({
                                label: `${vendor.company_name}`,
                                value: vendor.contact_id,
                            }))}
                        /> */}
                        {/* <FormikSelectGroup
                            formik={formik}
                            name={'customer'}
                            label={'Customer'}
                            options={(customers?.contacts ?? [])?.map(
                                (customer) => ({
                                    label: `${customer.customer_name}`,
                                    value: customer.contact_id,
                                })
                            )}
                        /> */}
                        <FormikAsyncSelect
                            name="customer"
                            label="Customer"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchCustomers({
                                        search: value,
                                        contact_type: 'customer',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: ele.contact_name,
                                        value: `${ele.contact_id}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(selectedOption) => {
                                const id = selectedOption.value;

                                formik.setFieldValue('customer', id);
                            }}
                        />
                        <div className="col-span-2">
                            <FormikMultiSelect
                                formik={formik}
                                name={'qualities'}
                                label={'Qualities'}
                                options={(quality.docs ?? []).map((qu) => ({
                                    label: `${qu.name}`,
                                    value: qu.name,
                                }))}
                            />
                        </div>
                        <div className="col-span-2 flex gap-3 ">
                            <FormikInputGroup
                                name={'rate'}
                                label={'Rate'}
                                formik={formik}
                            />
                            <FormikInputGroup
                                name={'qty_taka'}
                                label={'Qty Taka'}
                                formik={formik}
                            />
                            <FormikSelectGroup
                                name={'status'}
                                label="Status"
                                formik={formik}
                                options={[
                                    { label: 'Issued', value: 'open' },
                                    { label: 'Billed', value: 'billed' },
                                    { label: 'Cancelled', value: 'cancelled' },
                                    { label: 'All', value: '' },
                                ]}
                            />
                        </div>
                        <FormikInputDateGroup
                            name={'startDate'}
                            label={'Start Date'}
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            name={'endDate'}
                            label={'End Date'}
                            formik={formik}
                        />
                    </div>
                    <div className="flex gap-3 mt-4 items-center">
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? (
                                <Loader className="animate-spin" />
                            ) : (
                                'Submit'
                            )}
                        </PrimaryButton>
                        <SecondaryButton
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                setAdvanceSearchData({ reset: true });
                                formik.resetForm();
                                setIsOpen(false);
                            }}
                        >
                            Reset
                        </SecondaryButton>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default AdvanceSearchModal;
