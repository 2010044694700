import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getNotification,
    fetchNotifications,
} from '../../app/reducers/Notification/notificationSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchPurchaseOrders,
    getBills,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import SplitPopUp from './SplitPopUp';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import { customIDs } from '../../utils/customIds';
import { authAxiosInstance } from '../../utils/axiosConfig';

const Diagnosis = () => {
    const { notification } = useSelector(getNotification);
    const dispatch = useDispatch();
    const [diagnosisData, setDiagnosisData] = useState([]);
    const [splitExistData, setSplitExistData] = useState([]);
    const [orderCount, setOrderCount] = useState({});
    const [loading, setLoading] = useState(false);
    const { detail } = useParams();
    const paramsDetail = JSON.parse(atob(detail));
    const [splitPopupOpen, setSplitPopupOpen] = useState(false);

    useEffect(async () => {
        setLoading(true);
        const data = {
            date_start: paramsDetail.from,
            date_end: paramsDetail.to,
        };
        //check Vendor Exist or not
        if (paramsDetail?.vendorMobileNo) {
            data['vendor_id'] = paramsDetail.vendorId;
        } else {
            data[
                `custom_field_${customIDs.purchase_custom_buyerName}_contains`
            ] = paramsDetail.customerName;
        }

        let billData = {
            date_start: paramsDetail.from,
            date_end: paramsDetail.to,
        };
        //Check vendor Exist or not
        if (paramsDetail?.vendorMobileNo) {
            billData['vendor_id'] = paramsDetail.vendorId;
        } else {
            billData[
                `custom_field_${customIDs.purchase_bill_custom_customer_name}_contains`
            ] = paramsDetail.customerName;
        }
        const poDetail = await dispatch(fetchPurchaseOrders(data));

        let purchaseOrders = poDetail.payload?.purchaseorders;
        let addPurchaseOrdersAmount = [];
        for (let i = 0; i < purchaseOrders?.length; i++) {
            if (paramsDetail.vendorMobileNo) {
                if (
                    purchaseOrders[i]?.cf_commission_collection
                        ?.toLowerCase()
                        .trim() != 'customer'
                ) {
                    let poDetail = await authAxiosInstance.get(
                        `/purchase-orders/${purchaseOrders[i].purchaseorder_id}`
                    );
                    console.log(poDetail);
                    addPurchaseOrdersAmount = [
                        ...addPurchaseOrdersAmount,
                        {
                            ...purchaseOrders[i],
                            newAmount: parseFloat(
                                (parseFloat(
                                    purchaseOrders[i].cf_vendor_commission
                                ) *
                                    parseFloat(purchaseOrders[i].cf_qty_taka) *
                                    parseFloat(purchaseOrders[i].cf_rate) *
                                    poDetail?.data?.data?.purchaseorder
                                        ?.line_items[0].quantity) /
                                    100
                            ),
                        },
                    ];
                }
            } else if (
                purchaseOrders[i].cf_commission_collection
                    ?.toLowerCase()
                    .trim() != 'vendor'
            ) {
                addPurchaseOrdersAmount = [
                    ...addPurchaseOrdersAmount,
                    { ...purchaseOrders[i] },
                ];
            }
        }
        console.log(addPurchaseOrdersAmount, 'checking noq po');

        const billDetail = await dispatch(getBills(billData));
        let billOrders = billDetail.payload?.bills;
        let updateBillOrderDetails = [];
        for (let i = 0; i < billOrders?.length; i++) {
            if (paramsDetail.vendorMobileNo) {
                if (
                    billOrders[i]?.cf_commision_collection
                        ?.toLowerCase()
                        ?.trim() != 'customer'
                ) {
                    let billDetail = await authAxiosInstance.get(
                        `/purchase-orders/bill/order/${billOrders[i].bill_id}`
                    );
                    console.log(billDetail, 'billDetail');
                    updateBillOrderDetails = [
                        ...updateBillOrderDetails,
                        {
                            ...billOrders[i],
                            newAmount: parseFloat(
                                (parseFloat(
                                    billOrders[i]?.cf_vendor_commision
                                ) *
                                    parseFloat(billOrders[i].cf_qty_taka) *
                                    parseFloat(billOrders[i].cf_rate) *
                                    parseFloat(
                                        billDetail?.data?.data?.bill
                                            ?.line_items[0].quantity
                                    )) /
                                    100
                            ),
                        },
                    ];
                }
            } else if (
                billOrders[i].cf_commision_collection?.toLowerCase()?.trim() !=
                'vendor'
            ) {
                updateBillOrderDetails = [
                    ...updateBillOrderDetails,
                    { ...billOrders[i] },
                ];
            }
        }
        console.log(
            updateBillOrderDetails,
            'bill length',
            updateBillOrderDetails.length
        );

        let poData = addPurchaseOrdersAmount;
        setOrderCount({
            bill: updateBillOrderDetails.length,
            po: poData.length,
        });
        let mappedData = [];
        let splitData = [];
        for (let i = 0; i < poData.length; i++) {
            let findBill = updateBillOrderDetails?.find(
                (d) => d.reference_number == poData[i].purchaseorder_number
            );
            if (findBill) {
                if (
                    (findBill?.newAmount
                        ? findBill?.newAmount
                        : findBill.total) !=
                    (poData[i]?.newAmount
                        ? poData[i]?.newAmount
                        : poData[i]?.total)
                ) {
                    mappedData.push({
                        poNumber: poData[i].purchaseorder_number,
                        customerName: poData[i].cf_buyer_name,
                        reason: 'Amount Mismatch',
                    });
                }
            } else {
                mappedData.push({
                    poNumber: poData[i].purchaseorder_number,
                    customerName: poData[i].cf_buyer_name,
                    reason: 'Bill Not found for this No.',
                });
            }
            if (poData[i].cf_is_split) {
                splitData.push({
                    poNumber: poData[i].purchaseorder_number,
                    customerName: poData[i].cf_buyer_name,
                    reason: 'Split Exist',
                });
            }
        }
        for (let i = 0; i < updateBillOrderDetails.length; i++) {
            let bill = updateBillOrderDetails[i];
            let findPo = poData?.find(
                (d) => d.purchaseorder_number == bill.reference_number
            );
            if (findPo) {
                if (
                    (findPo?.newAmount ? findPo?.newAmount : findPo?.total) !=
                    (bill?.newAmount ? bill?.newAmount : bill?.total)
                ) {
                    mappedData.push({
                        poNumber: bill.reference_number,
                        customerName: bill.cf_customer_name,
                        reason: 'Amount Mismatch',
                    });
                }
            } else {
                mappedData.push({
                    poNumber: bill.reference_number,
                    customerName: bill.cf_customer_name,
                    reason: 'Po Not Found Not found for this Bill',
                });
            }
            // if (poData[i].cf_is_split) {
            //   splitData.push({ poNumber: poData[i].purchaseorder_number, customerName: poData[i].cf_buyer_name, reason: "Split Exist" })
            // }
        }
        setDiagnosisData(mappedData);
        setSplitExistData(splitData);
        setLoading(false);
    }, []);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchNotifications({ search: search, populate: true }));
        }, 300),
        []
    );

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'PO No.',
            accessor: 'poNumber',
        },
        {
            Header: 'Reason',
            accessor: 'reason',
        },
    ];

    return (
        <div className="p-4">
            <SplitPopUp
                setSplitModal={setSplitPopupOpen}
                viewSplitModal={splitPopupOpen}
                title={'Create Invoice'}
                data={{
                    customerId: paramsDetail.customerId,
                    customerMobile: paramsDetail.customerMobile,
                    from: paramsDetail.from,
                    to: paramsDetail.to,
                }}
                updateNotification={false}
            />
            <h1
                className="mb-2"
                style={{ fontWeight: 'bold', fontSize: '20px' }}
            >
                Diagnosis Report
            </h1>
            <div className="flex gap-4 items-center">
                <h1 className="mb-2">
                    CUSTOMER NAME:- {paramsDetail.customerName}
                </h1>
                <PrimaryButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setSplitPopupOpen(true);
                    }}
                >
                    Create Invoice
                </PrimaryButton>
            </div>
            {loading ? (
                <ClipLoader />
            ) : (
                <>
                    <h1 className="mb-2">
                        Purchase Order Count:- {orderCount.po}
                    </h1>
                    <h1 className="mb-2">Bill Count:- {orderCount?.bill}</h1>
                    <h1 className="mb-2">
                        Missing Count:- {orderCount?.po - orderCount?.bill}
                    </h1>
                    <TableWithHeadingAndGlobalSearch
                        loading={loading}
                        data={diagnosisData}
                        searchFunction={debouncedSearch}
                        columns={cols}
                    />
                    <div className="mt-4">
                        <ShowDropdown
                            heading="Show SplitExist Detail"
                            allowedRoles={['admin']}
                        >
                            <TableWithHeadingAndSearch
                                loading={loading}
                                data={splitExistData}
                                searchFunction={debouncedSearch}
                                columns={cols}
                            />
                        </ShowDropdown>
                    </div>
                </>
            )}
            {/* <PaginationClassic setPage={setPage} paginationDetails={users} /> */}
        </div>
    );
};

export default Diagnosis;
