import React from 'react';
import {
    Briefcase,
    Clipboard,
    Edit,
    FileMinus,
    FileText,
    Folder,
    Home,
    Mail,
    Monitor,
    User,
    UserPlus,
} from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
    return (
        <ul className="mt-3">
            {/* Dashboard */}
            <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/"
                name="Dashboard"
                Icon={<Monitor />}
                sidebarOpen={sidebarOpen}
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Add User',
                        link: '/addUser',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Edit User',
                        link: '/editUser',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View User',
                        link: '/viewUser',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Add Organization',
                        link: '/addOrganization',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View Organization',
                        link: '/viewOrganization',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="User"
                Icon={<UserPlus />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />
            <SidebarSingle
                Icon={<Clipboard />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/create-purchase-order"
                name="Purchase Order"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-purchase-order"
                name="View Purchase Order"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-previous-order"
                name="View Previous Order"
            />
            <SidebarGroup
                links={[
                    {
                        label: 'Quality',
                        link: '/createQuality',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Payment Term',
                        link: '/createPaymentTerm',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Reminder Subject',
                        link: '/reminderSubject',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading="Master"
                Icon={<Folder />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
            />{' '}
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/podelivery"
                name="Create Complain"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-complain"
                name="Complaint Summary"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/create-invoice"
                name="Invoice"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-invoice"
                name="View Invoices"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-vendors"
                name="View Vendors"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-customer"
                name="View Customers"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/report"
                name="Reports"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/ultraDiagnosis"
                name="Diagnosis Projects"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-notification"
                name="Notifications"
            />
            <SidebarSingle
                Icon={<FileText />}
                allowedRoles={['admin']}
                sidebarOpen={sidebarOpen}
                activePath="/view-sucess-notification"
                name="Success Notifications"
            />
        </ul>
    );
};

export default SidebarLinks;
