import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import {
    fetchCustomers,
    fetchVendors,
    getCustomer,
    updateCustomer,
} from '../../app/reducers/Customer/customerSlice';
import { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { Clock, Edit2 } from 'react-feather';
// import EditCustomer from "./EditCustomer";
import { customIDs } from '../../utils/customIds';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';

const ViewVendors = () => {
    const dispatch = useDispatch();
    const { customer_calling } = customIDs;
    const [index, setIndex] = useState(0);
    const [editData, setData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);

    useEffect(() => {
        dispatch(
            fetchVendors({
                contact_type: 'vendor',
            })
        );
    }, []);
    const { vendors, loading } = useSelector(getCustomer);

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'contact_name',
        },
        {
            Header: 'Company Name',
            accessor: 'company_name',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <>
                        {loadingReminder == row.original._id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log('row', row.original);
                                    setLoadingReminder(row.original._id);
                                    let popUpData = {};
                                    popUpData['vendorMobileNo'] =
                                        row.original?.mobile;
                                    popUpData['vendor_name'] =
                                        row.original?.company_name;

                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'vendor',
                                                reminderType: 'reminder',
                                                path: 'contacts',
                                                path_id:
                                                    row.original?.contact_id,
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original?.cf_reminder == 'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                    </>
                );
            },
        },
    ];

    // console.log(custom)
    const vendorsData = useMemo(
        () => (vendors?.contacts ? vendors.contacts : []),
        [vendors]
    );

    return (
        <>
            {/* <EditCustomer
        title={"Edit Contact"}
        setPurchaseModal={setModalOpen}
        viewPurchaseModal={modalOpen}
        data={editData}
      /> */}
            <div className="p-4" style={{ overflow: 'hidden' }}>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <TableWithHeadingAndSearch
                        heading={'Vendors'}
                        data={vendorsData}
                        loading={loading}
                        columns={cols}
                    />
                )}
            </div>
        </>
    );
};

export default ViewVendors;
