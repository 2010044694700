import React, { useEffect, useState, useRef } from 'react';
import {
    Document,
    Page,
    Image,
    View,
    PDFDownloadLink,
    StyleSheet,
} from '@react-pdf/renderer';
// import ChartJsImage from "chartjs-to-image";
// import { Chart } from "chart.js";
import moment from 'moment';
import { axiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Bar, Pie } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Tooltip,
    Legend
);

const PoReport = () => {
    const [barData, setBarData] = useState('');
    const [barImg, setBarImg] = useState('');
    const [pieImg, setPieImg] = useState('');
    const [pieData, setPieData] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsData = {};
    for (const [key, value] of queryParams) {
        paramsData[key] = value;
    }

    const chartRef = useRef();
    const pieRef = useRef();
    const styles = StyleSheet.create({
        image: {
            width: '80%',
            marginLeft: '10%',
            marginRight: '10%',
            marginTop: '5%',
        },
    });
    useEffect(async () => {
        const fetchPoData = async () => {
            try {
                let poUrlData = {
                    from: paramsData.from,
                    to: paramsData.to,
                    customer: paramsData.customer,
                };
                let poString = QueryString.stringify(poUrlData);
                const resp = await axiosInstance.get(
                    `purchase-orders/po/report?${poString}`
                );
                return resp?.data?.data;
            } catch (error) {
                console.log(error);
                toast.error('Failed to fetch Data');
            }
        };
        let poData = await fetchPoData();

        //Bar Chart Data
        let labelBar = poData?.invoiceDetail.map((d) =>
            moment(d.date).format('MMM YY')
        );
        let labelBarData = poData?.invoiceDetail.map((d) => d.total);

        let barDetail = {
            labels: labelBar,
            datasets: [
                {
                    label: 'Invoice Data',
                    data: labelBarData,
                    barPercentage: 0.5,
                    barThickness: 20,
                    maxBarThickness: 24,
                    backgroundColor: '#36a2eb',
                },
            ],
        };
        setBarData(barDetail);

        //Pie Chart Data
        const qualityQtyMap = {};
        poData?.poDetails?.forEach((item) => {
            const qualityName = item.cf_quality_name;
            const qtyTaka = parseInt(item.cf_qty_taka);

            if (qualityQtyMap.hasOwnProperty(qualityName)) {
                qualityQtyMap[qualityName] += qtyTaka;
            } else {
                qualityQtyMap[qualityName] = qtyTaka;
            }
        });

        const pieKeys = Object.keys(qualityQtyMap);
        const pieValues = Object.values(qualityQtyMap);
        const pieDetailData = {
            labels: pieKeys,

            datasets: [
                {
                    label: 'My First Dataset',
                    data: pieValues,
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                    ],
                    hoverOffset: 4,
                },
            ],
        };
        setPieData(pieDetailData);

        //Store Image
        setTimeout(() => {
            const chartContainer = chartRef.current.toBase64Image();
            const pieContainer = pieRef.current.toBase64Image();
            setBarImg(chartContainer);
            setPieImg(pieContainer);
        }, 1500);
    }, []);

    const generatePDF = () => {
        // Get the chart container element
        const chartContainer = chartRef.current.toBase64Image();
        const pieContainer = pieRef.current.toBase64Image();

        // Create a canvas from the chart container
        // html2canvas(chartContainer).then((canvas) => {
        //   const imgData = canvas.toDataURL('image/png');
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        doc.addImage(chartContainer, 'PNG', 15, 20);
        doc.addImage(pieContainer, 'PNG', 80, 400);
        doc.save('chart.pdf');
    };

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            {barData && pieData && (
                <>
                    <div
                        style={{
                            width: '100%',
                            height: '300px',
                            marginLeft: '15px',
                            marginRight: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Bar ref={chartRef} data={barData} />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '300px',
                            marginLeft: '15px',
                            marginRight: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Pie ref={pieRef} data={pieData} />
                    </div>
                    <button onClick={generatePDF}>Print to PDF</button>
                </>
            )}
            <div></div>
            {barImg && pieImg && (
                <PDFDownloadLink
                    document={
                        <Document>
                            <Page>
                                <View>
                                    <Image
                                        style={styles.image}
                                        src={barImg}
                                        alt="gfd"
                                    />
                                </View>
                                <View>
                                    <Image src={pieImg} alt="gfd" />
                                </View>
                            </Page>
                        </Document>
                    }
                    filename="report.pdf"
                >
                    Download Now
                </PDFDownloadLink>
            )}
            <div className="flex flex-col md:hidden w-screen h-screen justify-center items-center p-4 gap-4">
                <h1 className="items-center font-bold">
                    Thank You for making a purchase with Kukreja
                </h1>
            </div>
        </div>
    );
};

export default PoReport;
