import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { authAxiosInstance } from '../../utils/axiosConfig';
import moment from 'moment';

const EditCustomerModal = ({
    viewCustomerModal,
    setCustomerModal,
    title,
    data,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [creditScore, setCreditScore] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (!viewCustomerModal) {
            setStep(1);
        }
    }, [viewCustomerModal]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            customer: data.contact_name,
            credit: '',
            startDate: '',
            endDate: moment().format('YYYY-MM-DD'),
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let bodyData = {
                startDate: values.startDate,
                endDate: values.endDate,
                contact_name: values.customer,
                credit: values.credit,
            };
            let res = await authAxiosInstance.post(
                '/purchase-orders/creditReport',
                bodyData
            );
            if (res.data.data) {
                setCreditScore(res.data.data.creditScore);
                resetForm();
                setStep(2);
                setLoading(false);
            }
        },
    });

    useEffect(async () => {}, []);

    return (
        <ModalBasic
            title={title}
            modalOpen={viewCustomerModal}
            setModalOpen={setCustomerModal}
        >
            {step == 2 && (
                <>
                    <h1 className="text-2xl font-normal text-center">
                        Your Credit Score
                    </h1>
                    <h1 className="text-3xl font-bold text-center">
                        {creditScore}
                    </h1>
                </>
            )}
            {step == 1 && loading ? (
                <ClipLoader />
            ) : (
                step == 1 && (
                    <form
                        className="flex flex-col gap-4 p-4"
                        onSubmit={formik.handleSubmit}
                    >
                        <FormikInputGroup
                            name="customer"
                            label="Customer"
                            formik={formik}
                            readOnly
                        />
                        <FormikInputGroup
                            name="credit"
                            label="Credit Score"
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            name="startDate"
                            label="Start Date"
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            name="endDate"
                            label="End Date"
                            formik={formik}
                        />
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )
            )}
        </ModalBasic>
    );
};

export default EditCustomerModal;
