import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import { authAxiosInstance } from '../../utils/axiosConfig';
import moment from 'moment';

const AttachmentModal = ({ viewAttachmentModal, setAttachmentModal, data }) => {
    const dispatch = useDispatch();
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    useEffect(async () => {
        if (data.id) {
            console.log('insert', data.id);
            const action = await dispatch(fetchPurchaseOrder({ id: data.id }));
            if (action.payload.purchaseorder) {
                setPurchaseOrder(action.payload.purchaseorder);
            }
        }
    }, [data.id]);
    function calculateDateDifference(date) {
        const day = parseInt(date.substring(0, 5).split('/')[0]);
        const month = parseInt(date.substring(0, 5).split('/')[1]);
        const currentDate = moment();
        const targetDate = moment().set({
            month: month - 1,
            date: day,
        });
        if (currentDate.isBefore(targetDate)) {
            const previousYear = moment().subtract(1, 'year');
            const previousYearTargetDate = moment(previousYear).set({
                month: month - 1,
                date: day,
            });
            const daysDifference = currentDate.diff(
                previousYearTargetDate,
                'days'
            );
            console.log(
                `Number of days between ${date} and the current date: ${daysDifference} days (from the previous year)`
            );
            return daysDifference;
        } else {
            const daysDifference = targetDate.diff(currentDate, 'days');
            console.log(
                `Number of days between ${date} and the current date: ${daysDifference} days`
            );
            return daysDifference;
        }
    }
    return (
        <ModalBasic
            title={'Attachment Modal'}
            modalOpen={viewAttachmentModal}
            setModalOpen={setAttachmentModal}
        >
            {purchaseOrderLoading ? (
                <ClipLoader />
            ) : (
                <div>
                    {purchaseOrder?.documents?.map((d, index) => (
                        <div
                            style={{
                                padding: '10px',
                                color: 'blue',
                                textDecoration: 'underline',
                                marginLeft: '15px',
                            }}
                        >
                            <a
                                target="__blank"
                                href={`https://books.zoho.com/api/v3/purchaseorders/${data.id}/documents/${d.document_id}?organization_id=${process.env.REACT_APP_ZOHO_ORGANIZATION_ID}&inline=true`}
                            >
                                {d.uploaded_on_date_formatted}
                            </a>
                        </div>
                    ))}
                    {!purchaseOrder?.documents?.length && (
                        <h2 className="p-4">No Documents Found</h2>
                    )}
                </div>
            )}
        </ModalBasic>
    );
};

export default AttachmentModal;
