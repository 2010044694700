import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import { useSelector } from 'react-redux';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const SidebarGroup = ({
    sidebarExpanded,
    setSidebarExpanded,
    links,
    heading,
    Icon,
}) => {
    const location = useLocation();
    const { user } = useSelector(getAuth);
    const { pathname } = location;
    const isActive = links.reduce((acc, link) => {
        if (pathname.includes(link.link)) {
            acc = true;
        }
        return acc;
    }, false);

    return (
        <SidebarLinkGroup activecondition={isActive}>
            {(handleClick, open) => {
                console.log(
                    links.reduce((acc, ele) => {
                        acc = [...acc, ...ele.allowedRoles];
                        return acc;
                    }, [])
                );
                return (
                    <React.Fragment>
                        {links
                            .reduce((acc, ele) => {
                                acc = [...acc, ...ele.allowedRoles];
                                return acc;
                            }, [])
                            .includes(user.role) && (
                            <>
                                <a
                                    href="#0"
                                    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                        isActive && 'hover:text-slate-200'
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sidebarExpanded
                                            ? handleClick()
                                            : setSidebarExpanded(true);
                                    }}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            {Icon}

                                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                {heading}
                                            </span>
                                        </div>
                                        {/* Open Icon */}
                                        <div className="flex shrink-0 ml-2">
                                            <svg
                                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                                    open &&
                                                    'transform rotate-180'
                                                }`}
                                                viewBox="0 0 12 12"
                                            >
                                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                            </svg>
                                        </div>
                                    </div>
                                </a>
                                <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                    <ul
                                        className={`pl-9 mt-1 ${!open && 'hidden'}`}
                                    >
                                        {links.map((link, i) => (
                                            <React.Fragment key={i}>
                                                {link.allowedRoles.includes(
                                                    user.role
                                                ) && (
                                                    <li>
                                                        <NavLink
                                                            end
                                                            to={link.link}
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                'block text-slate-400 hover:text-slate-200 transition duration-150 truncate ' +
                                                                (isActive
                                                                    ? '!text-indigo-500'
                                                                    : '')
                                                            }
                                                        >
                                                            <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                                {link.label}
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </React.Fragment>
                );
            }}
        </SidebarLinkGroup>
    );
};

export default SidebarGroup;
