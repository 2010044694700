import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../partials/Header';
import Sidebar from '../../partials/SideBar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchReminderPopUps,
    getReminderPopUp,
    openReminderPopUp,
} from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import { toast } from 'react-toastify';
import { Info, X } from 'react-feather';
import ReminderPopUp from '../../pages/ReminderPopUp/ReminderPopUp';
import ViewReminderPopUp from '../../pages/ReminderPopUp/ViewReminderPopUp';
import { authAxiosInstance } from '../../utils/axiosConfig';
import moment from 'moment';

const RouteWithHeaderAndSidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        console.log('sidebar open', sidebarOpen);
        setSidebarOpen(!setSidebarOpen);
    }, [location]);
    const [currentPopUp, setCurrentPopUp] = useState({
        isOpen: false,
        data: {},
    });
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const reminderPopUp = useSelector(getReminderPopUp);
    const toastRef = useRef({});

    useEffect(() => {
        const pendingReminders = reminderPopUp?.reminderPopUp?.docs?.filter(
            (d) => d.status === 'pending'
        );
        reminderPopUp?.reminderPopUp?.docs &&
            reminderPopUp?.reminderPopUp?.docs?.length > 0 &&
            pendingReminders.forEach((element) => {
                const toastId = toast(
                    <div className="flex gap-2  text-sm">
                        <button
                            onClick={() => {
                                setCurrentPopUp({
                                    isOpen: true,
                                    data: element,
                                });
                            }}
                        >
                            <Info color="blue" />
                        </button>
                        <div className="flex flex-col">
                            <span>Customer: {element.customerName} </span>
                            <span> Vendor: {element.vendorName}</span>

                            <button
                                disabled={loading}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setLoading(true);
                                    let detail = {
                                        _id: element?._id,
                                        status: 'cancel',
                                        path: element?.path,
                                        path_id: element?.path_id,
                                        reminderType: element?.reminderType,
                                    };
                                    // await dispatch(editReminderPopUp(detail));
                                    await authAxiosInstance.patch(
                                        'reminderPopUp/cancel-reminder',
                                        detail
                                    );

                                    const today = new Date();
                                    const yesterday = new Date(today);
                                    yesterday.setDate(today.getDate() - 1);

                                    await dispatch(
                                        fetchReminderPopUps({
                                            date: {
                                                $lt: moment(today).unix(),
                                            },
                                            limit: 1,
                                            status: {
                                                $ne: 'cancel',
                                            },
                                        })
                                    );
                                    setLoading(false);

                                    toast.dismiss(toastId);
                                }}
                            >
                                <X color="red" />
                            </button>
                        </div>
                    </div>,
                    {
                        autoClose: false,
                        position: 'bottom-right',
                        toastId: '4abd',
                        closeButton: false,
                    }
                );
                if (!toastRef.current[element._id]) {
                    console.log('if condition');
                    toastRef.current[element._id] = toastId;
                }
            });
        return () => (toastRef.current = {});
    }, [reminderPopUp]);

    return (
        <div className="flex h-screen overflow-hidden">
            {' '}
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />{' '}
            {/*  Site header */}
            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <main className="relative">
                    <Outlet />
                </main>
                {reminderPopUp.isOpen && (
                    <ReminderPopUp
                        isOpen={reminderPopUp.isOpen}
                        setIsOpen={() =>
                            dispatch(
                                openReminderPopUp({
                                    isOpen: false,
                                    data: {},
                                })
                            )
                        }
                        data={reminderPopUp.data}
                    />
                )}
                <ViewReminderPopUp
                    isOpen={currentPopUp.isOpen}
                    setIsOpen={() =>
                        setCurrentPopUp((prev) => ({ ...prev, isOpen: false }))
                    }
                    data={currentPopUp.data}
                />
            </div>
        </div>
    );
};

export default RouteWithHeaderAndSidebar;
