import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getNotification,
    fetchNotifications,
    editNotification,
} from '../../app/reducers/Notification/notificationSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';
import SplitPopUp from './SplitPopUp';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';

const NotificationSuccess = () => {
    const { notification, loading } = useSelector(getNotification);
    const [splitData, setSplitData] = useState({});
    const [viewSplitModal, setSplitModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(
                fetchNotifications({
                    search: search,
                    populate: true,
                    type: 'SUCCESS',
                    limit: 500,
                })
            );
        }, 300),
        []
    );

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
        },
        {
            Header: 'PO URL',
            Cell: ({ row, data }) => {
                return (
                    <a
                        className="underline text-indigo-600"
                        target="poTargetTab"
                        href={`${row.original.poUrl}`}
                        // href={`${row.original.poUrl.replace(
                        //   "https://admin.kukrejaconnect.com",
                        //   "http://localhost:3000"
                        // )}`}
                    >
                        PO URL
                    </a>
                );
            },
        },
        {
            Header: 'PO Total',
            Cell: ({ row, data }) => {
                return parseInt(row.original?.poTotal)?.toFixed(2);
            },
        },
        {
            Header: 'Bill Total',
            Cell: ({ row, data }) => {
                return parseInt(row.original?.billTotal)?.toFixed(2);
            },
        },
        {
            Header: 'Difference Amount',
            Cell: ({ row, data }) => {
                return parseInt(
                    row.original?.poTotal - row.original?.billTotal
                )?.toFixed(2);
            },
        },
        {
            Header: 'Difference Percent',
            Cell: ({ row, data }) => {
                if (row.original.poTotal) {
                    return (
                        (
                            (parseFloat(
                                row.original?.poTotal - row.original?.billTotal
                            ) /
                                row.original?.poTotal) *
                            100
                        ).toFixed(2) + '%'
                    );
                } else {
                    return 'uncomputed';
                }
            },
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'from',
            accessor: 'from',
        },
        {
            Header: 'to',
            accessor: 'to',
        },

        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center">
                        {row.original?.type == 'SUCCESS' && (
                            <PrimaryButton
                                type="button"
                                onClick={async (e) => {
                                    e.stopPropagation();

                                    let custRespData =
                                        await authAxiosInstance.get(
                                            `/purchase-orders/contacts/${row.original?.customerId}`
                                        );
                                    let customerData =
                                        custRespData?.data?.data?.contact;
                                    let vendorDetails = {};
                                    if (customerData?.cf_vendor_mobile_number) {
                                        const data = {
                                            contact_type: 'vendor',
                                        };
                                        data['phone_contains'] =
                                            customerData?.cf_vendor_mobile_number;
                                        let stringData =
                                            QueryString.stringify(data);
                                        let respData =
                                            await authAxiosInstance.get(
                                                `/purchase-orders/search/vendor?${stringData}`
                                            );
                                        let vendorData =
                                            respData?.data?.data?.contacts;
                                        if (vendorData.length > 0) {
                                            vendorDetails['vendorId'] =
                                                vendorData[0].contact_id;
                                        }
                                    }

                                    const encodeData = {
                                        from: row.original.from,
                                        to: row.original.to,
                                        customerName:
                                            row.original?.customerName,
                                        customerId: row.original?.customerId,
                                        customerMobile: row.original?.phone,
                                        vendorMobileNo:
                                            customerData?.cf_vendor_mobile_number,
                                        vendorId: vendorDetails?.vendorId,
                                    };
                                    let encodedData = btoa(
                                        JSON.stringify(encodeData)
                                    );
                                    navigate(`/diagnosis/${encodedData}`);
                                }}
                            >
                                Run Diagnosis
                            </PrimaryButton>
                        )}
                        {row.original?.type == 'INVOICE_SPLIT' &&
                            row.original?.isSplit != 'true' && (
                                <PrimaryButton
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const data = {
                                            id: row.original._id,
                                            from: row.original.from,
                                            to: row.original.to,
                                            customerId:
                                                row.original?.customerId,
                                            gst_no: row.original?.gstNo,
                                        };
                                        setSplitData(data);
                                        setSplitModal(true);
                                    }}
                                >
                                    Split Invoice
                                </PrimaryButton>
                            )}
                        {row?.original?.isSplit && <div>Completed</div>}
                        <PrimaryButton
                            type="button"
                            onClick={async (e) => {
                                e.stopPropagation();
                                await dispatch(
                                    editNotification({
                                        _id: row?.original._id,
                                        status: 'COMPLETED',
                                    })
                                );
                                await dispatch(
                                    fetchNotifications({
                                        type: 'SUCCESS',
                                        status: { $ne: 'COMPLETED' },
                                    })
                                );
                            }}
                        >
                            Cancel
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    const data = useMemo(
        () => (notification?.docs ? notification.docs : []),
        [notification]
    );

    useEffect(() => {
        dispatch(
            fetchNotifications({
                type: 'SUCCESS',
                status: { $ne: 'COMPLETED' },
            })
        );
    }, []);

    return (
        <div className="p-4">
            <SplitPopUp
                viewSplitModal={viewSplitModal}
                setSplitModal={setSplitModal}
                title={'Create Invoice'}
                data={splitData}
            />
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            {/* <PaginationClassic setPage={setPage} paginationDetails={users} /> */}
        </div>
    );
};

export default NotificationSuccess;
