import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import {
    fetchCustomers,
    fetchVendors,
    getCustomer,
    updateCustomer,
} from '../..//app/reducers/Customer/customerSlice';
import { useMemo } from 'react';
import { ClipLoader, ClockLoader } from 'react-spinners';
import { Clock, Edit2 } from 'react-feather';
// import EditCustomer from "./EditCustomer";
import { customIDs } from '../../utils/customIds';
import EditCustomerModal from './EditCustomerModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { setEditId } from '../../app/reducers/Quality/qualitySlice';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';

const ViewCustomer = () => {
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const { customer_calling } = customIDs;
    const [index, setIndex] = useState(0);
    const [editData, setData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState(false);

    useEffect(() => {
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
            })
        );
    }, []);
    const { customers, loading } = useSelector(getCustomer);

    const cols = [
        // {
        //     Header: "Select",
        //     Cell: ({ row }) => {
        //         return (
        //             <input
        //                 type="checkbox"
        //                 onChange={(e) => {
        //                     if (e.target.checked) {
        //                         setSelectedRows([...selectedRows, row.original]);
        //                     } else {
        //                         let filtered = selectedRows.filter(
        //                             (ele) => ele.contact_id !== row.original.contact_id
        //                         );
        //                         setSelectedRows(filtered);
        //                     }
        //                 }}
        //                 checked={selectedRows.find(
        //                     (ele) => ele?.contact_id === row?.original?.contact_id
        //                 )}
        //             />
        //         );
        //     },
        // },
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'contact_name',
        },
        {
            Header: 'Company Name',
            accessor: 'company_name',
        },
        {
            Header: 'Q1',
            Cell: ({ row, data }) => {
                return row.original?.cf_q1 ? row.original?.cf_q1 : '';
            },
        },
        {
            Header: 'Q2',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q2
                    ? row.original?.cf_credit_score_fy_q2
                    : '';
            },
        },
        {
            Header: 'Q3',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q3
                    ? row.original?.cf_credit_score_fy_q3
                    : '';
            },
        },
        {
            Header: 'Q4',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q4
                    ? row.original?.cf_credit_score_fy_q4
                    : '';
            },
        },
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <div className="flex gap-3">
                        {loadingReminder == row.original._id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log('row', row.original);
                                    setLoadingReminder(row.original._id);
                                    let popUpData = {};
                                    popUpData['customerMobileNo'] =
                                        row.original?.mobile;
                                    popUpData['cf_buyer_name'] =
                                        row.original?.contact_name;
                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'customer',
                                                reminderType: 'reminder',
                                                path: 'contacts',
                                                path_id:
                                                    row.original?.contact_id,
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original['cf_reminder'] == 'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setData(row.original);
                                setModalOpen(true);
                            }}
                        >
                            Generate Credit Report
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    // console.log(custom)
    const customerData = useMemo(
        () => (customers?.contacts ? customers.contacts : []),
        [customers]
    );

    return (
        <>
            <EditCustomerModal
                title={'Check Report'}
                setCustomerModal={setModalOpen}
                viewCustomerModal={modalOpen}
                data={editData}
            />
            {selectedRows && selectedRows.length > 0 && (
                <div className="mt-3 mb-2 ms-5">
                    <PrimaryButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalOpen(true);
                        }}
                    >
                        Generate Credit Report
                    </PrimaryButton>
                </div>
            )}
            <div className="p-4" style={{ overflow: 'hidden' }}>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <TableWithHeadingAndSearch
                        heading={'Customer'}
                        data={customerData}
                        loading={loading}
                        columns={cols}
                    />
                )}
            </div>
        </>
    );
};

export default ViewCustomer;
