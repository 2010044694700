import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../components/formik/FormikInputGroup';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCustomers,
    fetchVendors,
    updateCustomer,
    getCustomer,
    fetchContact,
} from '../app/reducers/Customer/customerSlice';
import {
    fetchQualities,
    getQuality,
} from '../app/reducers/Quality/qualitySlice';
import { ClipLoader } from 'react-spinners';
import FormikSelectGroup from '../components/formik/FormikSelectGroup';
import FormikTextareaGroup from '../components/formik/FormikTextareaGroup';
import { generateOptions, generateQualityLabel } from '../utils/Utils';
import { customIDs } from '../utils/customIds';

const EditCustomer = ({ viewPurchaseModal, setPurchaseModal, title, data }) => {
    const dispatch = useDispatch();
    const { quality } = useSelector(getQuality);
    const { updateLoading } = useSelector(getCustomer);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.id,
            quality: '',
            priority: '',
            remark: '',
            custom_fields: [],
        },
        onSubmit: async (values, { resetForm }) => {
            let updatedData = {
                id: values.id,
                custom_fields: [
                    ...values.custom_fields,
                    {
                        customfield_id: customIDs.customer_calling,
                        value: '1',
                    },
                    {
                        customfield_id: customIDs.customer_title,
                        value: values.quality,
                    },
                    {
                        customfield_id: customIDs.customer_priority,
                        value: values.priority,
                    },
                ],
                notes: values.remark,
            };
            const action = await dispatch(updateCustomer(updatedData));
            if (action.payload) {
                if (data.index == 1) {
                    dispatch(
                        fetchCustomers({
                            contact_type: 'customer',
                            [`custom_field_${customIDs.customer_calling}_contains`]: 0,
                        })
                    );
                } else {
                    dispatch(
                        fetchVendors({
                            contact_type: 'vendor',
                            [`custom_field_${customIDs.customer_calling}_contains`]: 0,
                        })
                    );
                }
            }
            setPurchaseModal(false);
        },
    });

    useEffect(() => {
        dispatch(fetchQualities({ limit: 500 }));
    }, []);

    useEffect(async () => {
        if (data.id) {
            const action = await dispatch(fetchContact({ id: data.id }));
            if (action.payload.contact) {
                formik.setFieldValue(
                    'quality',
                    action.payload.contact.cf_title
                );
                formik.setFieldValue(
                    'priority',
                    action.payload.contact.cf_priority
                );
                formik.setFieldValue('remark', action.payload.contact.notes);
                formik.setFieldValue(
                    'custom_fields',
                    action.payload.contact.custom_fields
                );
            }
        }
    }, [data.id]);

    return (
        <ModalBasic
            title={title}
            modalOpen={viewPurchaseModal}
            setModalOpen={setPurchaseModal}
        >
            {updateLoading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="Contact Id"
                        formik={formik}
                        type="number"
                        name="id"
                        readOnly
                    />
                    <FormikSelectGroup
                        formik={formik}
                        label="Which Quality they make?"
                        name="quality"
                        options={generateOptions({
                            array: quality?.docs ? quality.docs : [],
                            labelField: 'name',
                            valueField: 'name',
                        })}
                        required
                    />
                    <FormikSelectGroup
                        formik={formik}
                        label="Priority"
                        name={`priority`}
                        options={generateOptions({
                            array: [{ label: 'HIGH', value: 'HIGH' }],
                            labelField: 'label',
                            valueField: 'value',
                        })}
                        required
                    />
                    <FormikTextareaGroup
                        formik={formik}
                        label="Remarks"
                        name="remark"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditCustomer;
