import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    reset,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
// import EditPurchaseModal from "./EditPurchaseModal";
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader, ClockLoader } from 'react-spinners';
import moment from 'moment';
import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu';
import EditComplain from './EditComplain';
import ResolveComplain from './ResolveComplain';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryBadge from '../../components/infrastructure/Badges/PrimaryBadge';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import TertiaryButton from '../../components/infrastructure/Buttons/TertiaryButton';
import { customIDs } from '../../utils/customIds';
import axios from 'axios';
import { toast } from 'react-toastify';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import { Clock } from 'react-feather';
const REQUIRED_TAILWIND = ['text-black-600', 'text-yellow-600', 'text-red-600'];

const ViewComplain = () => {
    const [page, setPage] = useState(1);
    const [viewComplainModal, setComplainModal] = useState(false);
    const [viewResolveModal, setResolveModal] = useState(false);
    const [editData, setEditData] = useState({});
    const organization_id = 637857442;
    const dispatch = useDispatch();
    const [loadingReminder, setLoadingReminder] = useState(false);

    const { purchaseOrder, loading } = useSelector(getPurchaseOrder); // created on 16-11-22

    // const purchaseOrdersData = useMemo(
    //   () => (purchaseOrder?.purchaseorders ? purchaseOrder.purchaseorders : []),
    //   [purchaseOrder]
    // );

    useEffect(async () => {
        await dispatch(reset());
    }, []);

    const purchaseOrdersData = useMemo(
        () =>
            purchaseOrder?.purchaseorders
                ? purchaseOrder.purchaseorders.filter(
                      (d) => d.cf_generate_complaint || d.cf_complaint_status
                  )
                : [],
        [purchaseOrder]
    );

    const purchaseOrdersRaised = useMemo(
        () =>
            purchaseOrder?.purchaseorders
                ? purchaseOrder.purchaseorders.filter(
                      (d) =>
                          !d?.cf_generate_complaint && !d?.cf_complaint_status
                  )
                : [],
        [purchaseOrder]
    );
    useEffect(async () => {
        await dispatch(
            fetchPurchaseOrders({
                page: page,
                [`custom_field_${customIDs.purchase_custom_has_complaint}_contains`]:
                    'yes',
            })
        );
    }, [page]);

    const purchaseOrderPageContext = useMemo(
        () => (purchaseOrder?.page_context ? purchaseOrder.page_context : []),
        [purchaseOrder]
    );

    const generateComplain = (e, data) => {
        e.stopPropagation();
        setEditData({
            id: data.purchaseorder_id,
            page: page,
        });
        setComplainModal(true);
    };
    const resolveComplain = (e, data) => {
        e.stopPropagation();
        setEditData({ id: data.purchaseorder_id, page: page });
        setResolveModal(true);
    };
    const complainClose = async (e, data) => {
        e.stopPropagation();
        console.log(data);
        let updateData = {
            id: data.purchaseorder_id,
            custom_fields: [
                {
                    value: '',
                    customfield_id: customIDs.purchase_payment_complaint,
                },
                {
                    value: '',
                    customfield_id: customIDs.purchase_custom_has_complaint,
                },
            ],
        };
        await dispatch(updatePurchaseOrderQuantity(updateData));
        dispatch(
            fetchPurchaseOrders({
                page: page,
                [`custom_field_${customIDs.purchase_custom_has_complaint}_contains`]:
                    'yes',
            })
        );
    };
    const complainFolloUp = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const complainFollowUp = await axios.post(
                `https://app2.com.bot/webhook/650d9f006b779408deaa67b4?type=followup`,
                action.payload.purchaseorder
            );
            if (complainFollowUp.data?.accepted) {
                toast.success('Complain Follow Up');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };
    const complainReminder = async (row) => {
        const action = await dispatch(
            fetchPurchaseOrder({ id: row.purchaseorder_id })
        );
        if (action.payload?.purchaseorder) {
            const complainReminder = await axios.post(
                `https://app2.com.bot/webhook/650da04f6b779408deaa6a53`,
                action.payload.purchaseorder
            );
            if (complainReminder.data?.accepted) {
                toast.success('Complain Reminder Done');
            } else {
                toast.error('Not accepted try again');
            }
        } else {
            toast.error('Something Went Wrong.Please try again');
        }
    };

    const cols = [
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                console.log('rowdata', row.original);
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div className="flex gap-2 items-center">
                            {loadingReminder ==
                            row.original.purchaseOrder?._id ? (
                                <ClockLoader size={25} speedMultiplier={2} />
                            ) : (
                                <button
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        setLoadingReminder(
                                            row.original.purchaseorder_id
                                        );
                                        let popUpData = {
                                            purchaseorder_number:
                                                row.original
                                                    ?.purchaseorder_number,
                                            cf_buyer_name:
                                                row.original?.cf_buyer_name,
                                            cf_quality_name:
                                                row.original?.cf_quality_name,
                                            cf_qty_taka:
                                                row.original?.cf_qty_taka,
                                            vendor_name:
                                                row.original?.vendor_name,
                                            purchaseorder_id:
                                                row.original.purchaseorder_id,
                                        };
                                        //Vendor Mobile No.
                                        const data = {
                                            id: row.original?.vendor_id,
                                        };
                                        const string =
                                            QueryString.stringify(data);
                                        const contacts =
                                            await authAxiosInstance.get(
                                                `purchase-orders/contacts/${data.id}?${string}`
                                            );
                                        if (contacts?.data?.data) {
                                            popUpData['vendorMobileNo'] =
                                                contacts?.data?.data?.contact?.contact_persons?.[0]?.mobile;
                                        }
                                        //Customer Mobile No.
                                        const customerData = {
                                            search: row.original?.cf_buyer_name,
                                        };
                                        const customerString =
                                            QueryString.stringify(customerData);
                                        const customerContacts =
                                            await authAxiosInstance.get(
                                                `purchase-orders/contacts?${customerString}`
                                            );
                                        console.log(customerContacts, 'new');
                                        if (customerContacts?.data?.data) {
                                            popUpData['customerMobileNo'] =
                                                customerContacts?.data?.data?.contacts?.[0]?.mobile;
                                        }
                                        dispatch(
                                            openReminderPopUp({
                                                isOpen: true,
                                                data: {
                                                    ...popUpData,
                                                    type: 'vendor',
                                                    reminderType:
                                                        'complain_reminder',
                                                    path: 'purchaseorders',
                                                    path_id:
                                                        row.original
                                                            .purchaseorder_id,
                                                },
                                            })
                                        );
                                        setLoadingReminder('');
                                    }}
                                >
                                    <Clock
                                        size={25}
                                        color={
                                            row.original['cf_po_reminder'] ==
                                                'YES' ||
                                            row.original[
                                                'cf_complaint_reminder'
                                            ] == 'YES'
                                                ? '#ffff1a'
                                                : 'black'
                                        }
                                    />
                                </button>
                            )}

                            <PrimaryButton
                                onClick={(e) =>
                                    generateComplain(e, row.original)
                                }
                            >
                                G
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={(e) =>
                                    resolveComplain(e, row.original)
                                }
                            >
                                R
                            </SecondaryButton>
                            <TertiaryButton
                                onClick={(e) => complainClose(e, row.original)}
                            >
                                C
                            </TertiaryButton>
                            <EditMenu
                                align="left"
                                className="relative inline-flex"
                            >
                                <li>
                                    <span
                                        onClick={() =>
                                            complainFolloUp(row.original)
                                        }
                                        className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Complaint Followup
                                    </span>
                                </li>
                                <hr></hr>
                                <li>
                                    <span
                                        onClick={() =>
                                            complainReminder(row.original)
                                        }
                                        className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Complain Remainder
                                    </span>
                                </li>
                            </EditMenu>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'PURCHASE ORDER#',
            accessor: 'purchaseorder_number',
            Cell: ({ row }) => {
                let tag = 'complaintRaised';
                const yellowDate = moment(
                    row.original.cf_complaint_open_date,
                    'DD-MM-YYYY'
                ).add(4, 'days');
                // console.log(yellowDate);
                // console.log(moment().isAfter(yellowDate), "is after check");
                const redDate = moment(
                    row.original.cf_complaint_open_date,
                    'DD-MM-YYYY'
                ).add(12, 'days');
                let dateTag = 'black-600';
                if (moment().isAfter(yellowDate)) {
                    dateTag = 'yellow-600';
                    console.log(dateTag);
                }
                if (moment().isAfter(redDate)) {
                    dateTag = 'red-600';
                }
                if (row.original.cf_tag === 'PAYMENT COMPLAINT') {
                    tag = 'complaintAccepted';
                }
                if (row.original.cf_tag_2 === 'COMPLAINT SOLVE') {
                    tag = 'complaintSolved';
                }
                return (
                    <div className="flex flex-col justify-center items-start">
                        <span className={`text-${dateTag}`}>
                            {row.original.purchaseorder_number}
                        </span>
                        {
                            {
                                complaintRaised: (
                                    <PrimaryBadge>
                                        Complaint Raised
                                    </PrimaryBadge>
                                ),
                                complaintAccepted: (
                                    <PrimaryBadge>
                                        Complaint Accepted
                                    </PrimaryBadge>
                                ),
                                complaintSolved: (
                                    <PrimaryBadge>
                                        Complaint Solved
                                    </PrimaryBadge>
                                ),
                            }[tag]
                        }
                    </div>
                );
            },
            style: {
                width: '12%',
            },
        },
        {
            Header: 'QTY TAKA',
            accessor: 'cf_qty_taka',
            style: {
                width: '8%',
            },
        },
        {
            Header: 'QUALITY NAME',
            accessor: 'cf_quality_name',
        },
        {
            Header: 'VENDOR NAME',
            accessor: 'vendor_name',
            Cell: ({ row }) => {
                // const [firstEle, secondEle] = row.original.cf_complaint_note?.length
                //   ? row.original.cf_complaint_note.split("---")
                //   : "";
                return (
                    <div className="grid grid-cols-1">
                        <span>{row.original.vendor_name}</span>
                        {row.original?.cf_complaint_note && (
                            // <div className="flex">
                            //   <PrimaryBadge>{firstEle}</PrimaryBadge>
                            //   <span className="text-[10px]">{secondEle}</span>
                            // </div>
                            <div className="flex">
                                <PrimaryBadge>
                                    {row.original.cf_complaint_note}
                                </PrimaryBadge>
                                <span className="text-[10px]">
                                    {moment(
                                        row.original?.cf_complaint_open_date,
                                        'DD-MM-YYYY'
                                    ).format('DD/MM')}
                                </span>
                            </div>
                        )}
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'BUYER NAME',
            accessor: 'cf_buyer_name',
            Cell: ({ row }) => {
                const [firstEle, secondEle] = row.original.cf_solve_note?.length
                    ? row.original.cf_solve_note.split('---')
                    : '';
                return (
                    <div className="grid grid-cols-1">
                        <span>{row.original.cf_buyer_name}</span>
                        {row.original?.cf_solve_note && (
                            <div className="flex">
                                <PrimaryBadge>{firstEle}</PrimaryBadge>
                                <span className="text-[10px]">{secondEle}</span>
                            </div>
                        )}
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span>
                            Issue Date{' '}
                            {moment(row.original.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                            Delivery Date{' '}
                            {row?.original?.delivery_date
                                ? moment(row.original.delivery_date).format(
                                      'DD/MM/YYYY'
                                  )
                                : ''}
                        </span>
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'RATE',
            accessor: 'cf_rate',
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {};
            data['vendor_name_contains'] = search;
            dispatch(
                fetchPurchaseOrders({
                    ...data,
                    page: 1,
                    status: 'open',
                    [`custom_field_${customIDs.purchase_custom_has_complaint}_contains`]:
                        'yes',
                })
            );
        }, 300),
        []
    );

    return (
        <div className="p-4">
            <EditComplain
                title={'Edit Complain'}
                setComplainModal={setComplainModal}
                viewComplainModal={viewComplainModal}
                data={editData}
            />
            <ResolveComplain
                title={'Resolve Complain'}
                setResolveModal={setResolveModal}
                viewResolveModal={viewResolveModal}
                data={editData}
            />

            <div style={{ marginBottom: '20px' }}>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <TableWithHeadingAndSearch
                        heading={'UnApproved Complaints'}
                        placeholder={`Search Vendor`}
                        data={purchaseOrdersRaised}
                        columns={cols}
                    />
                )}
            </div>

            {loading ? (
                <ClipLoader />
            ) : (
                <div className="">
                    <TableWithHeadingAndSearch
                        heading={
                            <div className="flex gap-2">
                                <h1 className="font-semibold text-slate-800">
                                    Complains
                                </h1>
                                <PrimaryButton type="button" className={''}>
                                    <a href="/view-group-complaint">Group</a>
                                </PrimaryButton>
                            </div>
                        }
                        placeholder={`Search Vendor`}
                        data={purchaseOrdersData}
                        columns={cols}
                    />
                </div>
            )}
            {!loading && (
                <div className="mt-4 flex gap-3 justify-end">
                    {page > 1 && (
                        <PrimaryButton onClick={(e) => setPage(page - 1)}>
                            Previous
                        </PrimaryButton>
                    )}
                    {purchaseOrderPageContext.has_more_page && (
                        <PrimaryButton onClick={(e) => handleNext(e)}>
                            Next
                        </PrimaryButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewComplain;
