import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    fetchQualities,
    getQuality,
    createQuality,
    deleteQuality,
} from '../../app/reducers/Quality/qualitySlice';
import { Edit2, Trash } from 'react-feather';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useCallback } from 'react';
import { useMemo } from 'react';
import {
    createPaymentTerm,
    deletePaymentTerm,
    fetchPaymentTerms,
    getPaymentTerm,
    setEditId,
} from '../../app/reducers/PaymentTerms/paymentTermSLice';
import EditPaymentTermsModal from './EditPaymentTerms';

const PaymentTerms = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPaymentTerms());
    }, []);
    const { paymentTerms, loading } = useSelector(getPaymentTerm);

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Value',
            accessor: 'value',
        },
        {
            Header: 'Percentage',
            accessor: 'percentage',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            onClick={() => {
                                dispatch(setEditId(row.original._id));
                            }}
                        />
                        <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deletePaymentTerm({ id: row.original._id })
                                );
                                dispatch(fetchPaymentTerms());
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(fetchQualities({ search: search }));
        }, 300),
        []
    );
    const data = useMemo(
        () => (paymentTerms?.docs ? paymentTerms.docs : []),
        [paymentTerms]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            value: '',
            percentage: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            value: Yup.number().required(),
            percentage: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            await dispatch(createPaymentTerm({ ...values }));
            dispatch(fetchPaymentTerms());
            resetForm();
        },
    });

    return (
        <PageWithCard heading="Payment Terms">
            <EditPaymentTermsModal />
            <div className="text-slate-800 font-semibold mb-4">
                Payment Terms
            </div>
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-2"
                >
                    <FormikInputGroup
                        label="Name"
                        formik={formik}
                        name="name"
                        required
                    />
                    <FormikInputGroup
                        label="Value"
                        formik={formik}
                        name="value"
                        required
                    />
                    <FormikInputGroup
                        label="Percentage"
                        formik={formik}
                        name="percentage"
                        required
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
            <TableWithHeadingAndGlobalSearch
                heading="Payment Terms"
                data={data}
                columns={columns}
                loading={loading}
                searchFunction={(value) => {
                    debouncedSearch(value);
                }}
            />
        </PageWithCard>
    );
};
export default PaymentTerms;
