import React, { useState } from 'react';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { X } from 'react-feather';
import { customIDs } from '../../utils/customIds';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';

const ReportRow = ({
    formik,
    arrayHelpers,
    reportObj,
    index: i,
    element: ele,
    formikValues,
}) => {
    let [chalanOption, setChalanOptions] = useState([]);
    let [loading, setLoading] = useState(false);
    let [search, setSearch] = useState();
    let [edit, setEdit] = useState(false);

    const onKeyPressAdd = async (event, arrayHelpers) => {
        event.stopPropagation();
        if (event.keyCode === 13 && event.target.value) {
            arrayHelpers.push(reportObj);
        }
    };

    return (
        <div className="flex justify-between gap-2">
            <div>
                <X
                    onClick={() => {
                        arrayHelpers.remove(i);
                    }}
                />
                {ele.barcode}
            </div>
            <div className="w-60">
                {ele.group && !edit && !ele.edit ? (
                    <div>{ele.group}</div>
                ) : (
                    <FormikAsyncSelect
                        name={`reports.${i}.group`}
                        formik={formik}
                        getOptions={async (value) => {
                            setSearch(value);

                            //Challan Name Search
                            let payload = {
                                [`custom_field_${customIDs.customer_chalan_name}_contains`]:
                                    value,
                                contact_type: 'vendor',
                            };
                            let string = QueryString.stringify(payload);
                            const actionDetails = await authAxiosInstance.get(
                                `purchase-orders/chalan/contacts?${string}`
                            );
                            let chalanDetails =
                                actionDetails.data?.data?.contacts;

                            // Chalan Name 1
                            // let payloadDetails = {
                            //   [`custom_field_${customIDs.customer_chalan_name1}_contains`]:
                            //     value,
                            // };
                            // let string2 = QueryString.stringify(payloadDetails);
                            // const action = await authAxiosInstance.get(
                            //   `purchase-orders/chalan/contacts?${string2}`
                            // );
                            // let chalanName1Details = action.data?.data?.contacts;

                            //Merge Details
                            let mergeDetails = [...chalanDetails].map((d) =>
                                d?.company_name
                                    ? d?.company_name
                                    : d.contact_name
                            );
                            let finalData = [...new Set(mergeDetails)];
                            console.log(finalData, 'finalData');
                            const serverResp = finalData.map((ele) => ({
                                label: `${ele}`,
                                value: `${ele}`,
                            }));

                            return [...serverResp];
                        }}
                        onChange={async (option) => {
                            console.log(option, 'option', search);
                            setLoading(true);
                            //Find details of grop name
                            let payloadDetails = {
                                ['contact_name_contains']: option.value,
                                contact_type: 'vendor',
                            };
                            let string2 = QueryString.stringify(payloadDetails);
                            const action = await authAxiosInstance.get(
                                `purchase-orders/chalan/contacts?${string2}`
                            );
                            let companyDetails = action.data?.data?.contacts[0];
                            let chalanDetails = [];
                            if (companyDetails) {
                                //Set Challan Options
                                chalanDetails = [
                                    ...new Set([
                                        ...companyDetails?.cf_chalan_name?.split(
                                            ','
                                        ),
                                    ]),
                                ];
                                // Fetch Po Detials
                                let poFetchDetails = {
                                    [`custom_field_${customIDs.purchase_custom_buyerName}_contains`]:
                                        formik.values.customer,
                                    vendor_id: companyDetails?.contact_id,
                                    date_start: moment(
                                        formik.values.start
                                    ).format('YYYY-MM-DD'),
                                    date_end: moment(formik.values.end).format(
                                        'YYYY-MM-DD'
                                    ),
                                };
                                let string3 =
                                    QueryString.stringify(poFetchDetails);
                                const action3 = await authAxiosInstance.get(
                                    `purchase-orders?${string3}`
                                );
                                let poDetails =
                                    action3.data?.data?.purchaseorders;
                                console.log(poDetails, 'poDetailhu');
                                if (poDetails?.length <= 0) {
                                    setLoading(false);
                                    return alert('Purchase Orders Not Found');
                                }
                            }
                            setChalanOptions(
                                chalanDetails
                                    ?.sort(
                                        (a, b) =>
                                            a.toLowerCase() - b.toLowerCase()
                                    )
                                    ?.map((d) => ({ label: d, value: d }))
                            );

                            let findData = chalanDetails.find((d) =>
                                d.toLowerCase().includes(search.toLowerCase())
                            );
                            if (findData) {
                                formik.setFieldValue(
                                    `reports.${i}.chalan`,
                                    findData
                                );
                            }
                            formik.setFieldValue(
                                `reports.${i}.group`,
                                option.value
                            );
                            setLoading(false);
                        }}
                    />
                )}
            </div>
            <div className="w-60">
                {loading ? (
                    <ClipLoader />
                ) : ele.chalan && !edit & !ele.edit ? (
                    <div>{ele.chalan}</div>
                ) : (
                    <FormikSelectGroup
                        formik={formik}
                        name={`reports.${i}.chalan`}
                        options={generateOptions({
                            array: chalanOption ? chalanOption : [],
                            labelField: 'label',
                            valueField: 'value',
                        })}
                    />
                )}
            </div>
            <div>
                <FormikInputGroup
                    name={`reports.${i}.amount`}
                    readOnly={!edit && !ele.edit}
                    onChange={(e) => {
                        let value = e.target.value;
                        console.log(value);
                        let finalAmount = value;
                        if (formik.values.gst == 'yes') {
                            let gstAmount = (value * 5) / 100;
                            finalAmount = finalAmount - gstAmount;
                        }
                        formik.setFieldValue(
                            `reports.${i}.finalAmount`,
                            finalAmount
                        );
                        let brockerage = formik.values.brockrage;
                        let finalBrockerage = (finalAmount * brockerage) / 100;
                        formik.setFieldValue(
                            `reports.${i}.brockrage`,
                            finalBrockerage
                        );
                        formik.setFieldValue(`reports.${i}.amount`, value);
                    }}
                    onKeyDown={(e) => {
                        onKeyPressAdd(e, arrayHelpers);
                        // formik.setFieldValue(
                        //   `product.${index}.id`,
                        //   ""
                        // );
                    }}
                    formik={formik}
                    type="number"
                    min={0}
                />
            </div>
            <div>
                <FormikInputGroup
                    name={`reports.${i}.finalAmount`}
                    formik={formik}
                    value={parseFloat(
                        formik.values.reports[i].finalAmount
                    ).toFixed(2)}
                    type="number"
                    min={0}
                    readOnly
                />
            </div>
            <div>
                <FormikInputGroup
                    name={`reports.${i}.brockrage`}
                    formik={formik}
                    value={parseFloat(
                        formik.values.reports[i].brockrage
                    ).toFixed(2)}
                    readOnly
                    type="number"
                    min={0}
                />
            </div>
            <div>
                {!ele.edit && (
                    <PrimaryButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setEdit((prev) => !prev);
                        }}
                    >
                        Edit
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default ReportRow;
