import React, { useState } from 'react';
import UserMenu from '../components/infrastructure/UserMenu';
import moment from 'moment';
import _ from 'lodash';
import { Bell, Clock } from 'react-feather';
import { Link } from 'react-router-dom';
import {
    getReminderPopUp,
    openReminderPopUp,
} from '../app/reducers/ReminderPopUp/reminderPopUpSlice';
import { useDispatch, useSelector } from 'react-redux';

// import SearchModal from '../components/ModalSearch';
// import Notifications from '../components/DropdownNotifications';
// import Help from '../components/DropdownHelp';
// import UserMenu from '../components/DropdownProfile';

function Header({ sidebarOpen, setSidebarOpen }) {
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [dateDifference, setDateDifference] = useState(false);
    const { reminderPopUp } = useSelector(getReminderPopUp);
    const dispatch = useDispatch();

    return (
        <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    {/* Header: Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="text-slate-500 hover:text-slate-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>

                    {/* Header: Right side */}
                    <div className="flex items-center space-x-3">
                        <input
                            placeholder="Enter complaint date"
                            type="text"
                            onChange={(e) => {
                                // console.log(e.target.value);
                                const dateStr = e.target.value;
                                const day = parseInt(
                                    dateStr.substring(0, 2),
                                    10
                                );
                                const month = parseInt(
                                    dateStr.substring(2),
                                    10
                                );
                                console.log('month', month);
                                console.log('day', day);
                                const currentDate = moment();
                                const targetDate = moment().set({
                                    month: month - 1,
                                    date: day,
                                });
                                console.log('targetDate', targetDate);
                                if (currentDate.isBefore(targetDate)) {
                                    const previousYear = moment().subtract(
                                        1,
                                        'year'
                                    );
                                    const previousYearTargetDate = moment(
                                        previousYear
                                    ).set({
                                        month: month - 1,
                                        date: day,
                                    });
                                    const daysDifference = currentDate.diff(
                                        previousYearTargetDate,
                                        'days'
                                    );
                                    setDateDifference(daysDifference);
                                    console.log(
                                        `Number of days between ${dateStr} and the current date: ${daysDifference} days (from the previous year)`
                                    );
                                } else {
                                    const daysDifference = targetDate.diff(
                                        currentDate,
                                        'days'
                                    );
                                    setDateDifference(daysDifference);

                                    console.log(
                                        `Number of days between ${dateStr} and the current date: ${daysDifference} days`
                                    );
                                }
                            }}
                        />
                        {_.isFinite(dateDifference) && (
                            <h1 className="w-20 ">{`${dateDifference} days`}</h1>
                        )}
                        {/*  Divider */}

                        <Link to={'/viewRemindersPage'} className="relative">
                            <Bell size={30} />
                            <div className=" absolute top-0 right-0 translate-x-2 -translate-y-2 bg-green-500 size-5 rounded-full text-xs flex items-center justify-center text-white">
                                {reminderPopUp?.totalDocs
                                    ? reminderPopUp.totalDocs
                                    : 0}
                            </div>
                        </Link>
                        <button
                            onClick={() =>
                                dispatch(
                                    openReminderPopUp({
                                        isOpen: true,
                                        data: {},
                                    })
                                )
                            }
                        >
                            <Clock />
                        </button>

                        <UserMenu align="right" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
