import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';

const EditPurchaseModal = ({
    viewPurchaseModal,
    setPurchaseModal,
    title,
    data,
}) => {
    const [isDisableButton, setIsDisableButton] = useState(true);
    const [isDisableTakaButton, setIsDisableTakaButton] = useState(true);
    const dispatch = useDispatch();
    console.log(data.page);
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);
    const [quantity, setQuantity] = useState(true);
    const [qtyTaka, setQtyTaka] = useState(true);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.id,
            quantity: '',
            qtyTaka: '',
            line_items: [],
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let data = {
                id: values.id,
                line_items: [
                    {
                        ...values.line_items[0],
                        quantity: values.quantity,
                        item_custom_fields: [
                            ...values.line_items[0].item_custom_fields,
                            {
                                customfield_id:
                                    customIDs.purchase_item_custom_field_qty_taka,
                                value: values.qtyTaka,
                            },
                        ],
                    },
                ],
                custom_fields: [
                    {
                        customfield_id:
                            customIDs.purchase_custom_field_qty_taka,
                        value: values.qtyTaka,
                    },
                ],
            };
            await dispatch(updatePurchaseOrderQuantity(data));
            setTimeout(async () => {
                await dispatch(convertToBill({ id: values.id }));
                dispatch(
                    fetchPurchaseOrders({ page: data.page, status: 'open' })
                );
                setPurchaseModal(false);
                setLoading(false);
            }, 30000);
        },
    });

    useEffect(async () => {
        if (data.id) {
            console.log('insert', data.id);
            const action = await dispatch(fetchPurchaseOrder({ id: data.id }));
            if (action.payload.purchaseorder) {
                formik.setFieldValue(
                    'quantity',
                    action.payload.purchaseorder.total_quantity
                );
                formik.setFieldValue(
                    'qtyTaka',
                    action.payload.purchaseorder.custom_field_hash?.cf_qty_taka
                );
                formik.setFieldValue(
                    'line_items',
                    action.payload.purchaseorder.line_items
                );
            }
        }
    }, [data.id]);

    return (
        <ModalBasic
            title={title}
            modalOpen={viewPurchaseModal}
            setModalOpen={setPurchaseModal}
        >
            {purchaseOrderLoading || loading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="PO Number"
                        formik={formik}
                        type="number"
                        name="id"
                        readOnly
                    />
                    <div className="flex gap-2">
                        <div style={{ width: '90%' }}>
                            <FormikInputGroup
                                label="Quantity"
                                formik={formik}
                                type="number"
                                name="quantity"
                                readOnly={quantity}
                            />
                        </div>
                        <div>
                            <div style={{ visibility: 'hidden' }}>Hi</div>
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setQuantity((prev) => !prev);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div style={{ width: '90%' }}>
                            <FormikInputGroup
                                name="qtyTaka"
                                type="number"
                                label="QTY Taka"
                                formik={formik}
                                readOnly={qtyTaka}
                            />
                        </div>
                        <div>
                            <div style={{ visibility: 'hidden' }}>Hi</div>
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setQtyTaka((prev) => !prev);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </div>
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditPurchaseModal;
