import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // fetchReminderPopUps,
    getReminderPopUp,
} from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import moment from 'moment';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
// import ReminderPopUp from '../ReminderPopUp/ReminderPopUp';
import ViewReminderPopUp from '../ReminderPopUp/ViewReminderPopUp';
import { Link } from 'react-router-dom';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';

const ViewRemindersPage = () => {
    const [reminderPopUp, setReminderPopUp] = useState([]);

    const dispatch = useDispatch();
    const { reminderPopUp: reduxReminderPopups, loading: reminderLoading } =
        useSelector(getReminderPopUp);
    const fetchReminderPopUps = async () => {
        try {
            const string = QueryString.stringify({
                status: { $ne: 'cancel' },
                // date: { $lt: moment(new Date()).unix() },
            });
            const resp = await authAxiosInstance.get(
                `/reminderPopUp?${string}`
            );

            const docs = resp.data?.data?.docs;
            if (docs) setReminderPopUp(docs);
        } catch (error) {
            console.log('ViewRemindersPage', error);
        }
    };
    useEffect(() => {
        fetchReminderPopUps();
        // dispatch(
        //     fetchReminderPopUps({
        //         date: { $lt: moment(new Date()).unix() },
        //         status: { $ne: 'cancel' },
        //     })
        // );
    }, [reduxReminderPopups.docs]);
    useEffect(() => {
        fetchReminderPopUps();
    }, []);

    const [reminderPopUpState, setReminderPopUpState] = useState({
        isOpen: false,
        data: [],
    });

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row }) => {
                return (
                    <button
                        className="text-blue-500 underline"
                        onClick={(e) => {
                            e.stopPropagation();
                            setReminderPopUpState((prev) => ({
                                isOpen: true,

                                data: row?.original,
                            }));
                        }}
                    >
                        {' '}
                        {row.index + 1}
                    </button>
                );
            },
        },
        {
            Header: 'Reminder Date',
            Cell: ({ row }) => {
                return moment(row.original?.createdAt).format(
                    'DD/MM/YYYY-HH:mm'
                );
            },
        },
        {
            Header: 'due date',
            Cell: ({ row }) => {
                return moment(row.original?.date * 1000).format(
                    'DD/MM/YYYY-HH:mm'
                );
            },
        },
        {
            Header: 'PO No.',
            accessor: 'poNumber',
        },
        {
            Header: 'type',
            accessor: 'type',
        },
        {
            Header: 'Subject',
            accessor: 'subject',
        },
        {
            Header: 'customer name',
            accessor: 'customerName',
        },
        {
            Header: 'vendor name',
            accessor: 'vendorName',
        },
        {
            Header: 'Quality name',
            accessor: 'qualityName',
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
        },
        {
            Header: 'rate',
            accessor: 'rate',
        },
        {
            Header: 'pdf',
            Cell: ({ row }) => {
                return (
                    <Link className="text-blue-500" to={row.original?.pdfLink}>
                        {row.original?.pdfLink ? 'Open file' : ''}
                    </Link>
                );
            },
        },

        {
            Header: 'status',
            accessor: 'status',
        },
    ];

    return (
        <div>
            <ViewReminderPopUp
                isOpen={reminderPopUpState.isOpen}
                setIsOpen={(e) => {
                    setReminderPopUpState((prev) => !prev.isOpen);
                }}
                data={reminderPopUpState.data}
            />
            <TableWithHeadingAndSearch
                data={reminderPopUp}
                heading="View Reminders"
                columns={cols}
            />
        </div>
    );
};

export default ViewRemindersPage;
